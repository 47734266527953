import './styles/index.css';
import './styles/Modal.css';
import { useEffect, useState } from "react";
import PermissionStatus from "./components/PermissionStatus";

const App = () => {
    const [manager, setManager] = useState({
        locationPermission: 'prompt',
        dataFetched: false,
        weatherData: null,
        locationData:{
            state: undefined,
            country: undefined
        },
        dataLoaded: false,
        loading: false,
    });

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    const success = (pos) => {
        const { latitude, longitude } = pos.coords;

        if (!manager.dataFetched) {
            setManager(prevState => ({ ...prevState, loading: true }));
            getWeather(latitude, longitude);
            getLocationName(latitude, longitude)
        }

        setManager(prevState => ({
            ...prevState,
            locationPermission: 'granted',
            dataFetched: true,
        }));
    };

    const errors = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    };

    const getWeather = async (lat, long) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}latitude=${lat}&longitude=${long}&current=temperature_2m,relative_humidity_2m,snowfall,apparent_temperature,rain,visibility&daily=sunrise`);
            const data = await response.json();
            setManager(prevState => ({
                ...prevState,
                weatherData: data,
                dataLoaded: true,
                loading: false,
            }));
        } catch (error) {
            console.error('Error fetching weather data:', error);
            setManager(prevState => ({
                ...prevState,
                loading: false,
            }));
        }
    };

    const getLocationName = async (lat, long) => {
        let value = `${lat},${long}`;

        try{
            fetch(`https://api.positionstack.com/v1/reverse?access_key=${process.env.REACT_APP_POSITONSTACK_API_KEY}&limit=1&query=${value}`)
                .then((response) => response.json()) //parse json data
                .then(function (data) {
                    setManager(prevState => ({
                        ...prevState,
                        locationData:{
                            state: data.data[0].county,
                            country: data.data[0].country
                        },
                    }));
                });
        } catch (err){
            console.info(err)
        }
    }

    useEffect(() => {
        const checkGeolocation = async () => {
            if (navigator.geolocation) {
                try {
                    const result = await navigator.permissions.query({ name: "geolocation" });
                    if (result.state === "granted" || result.state === "prompt") {
                        navigator.geolocation.getCurrentPosition(success, errors, options);
                    } else if (result.state === "denied") {
                        setManager(prevState => ({
                            ...prevState,
                            locationPermission: 'denied',
                        }));
                    }
                } catch (error) {
                    console.error('Error querying geolocation permissions:', error);
                }
            } else {
                setManager(prevState => ({
                    ...prevState,
                    locationPermission: 'error',
                }));
            }
        };

        checkGeolocation();
    }, [options]);

    return (
        <PermissionStatus
            status={manager.locationPermission}
            weatherData={manager.weatherData}
            locationData={manager.locationData}
            loading={manager.loading}
        />
    );
};

export default App;
