import React from 'react';
import githubIcon from "../../images/github-mark.svg";
import '../../styles/index.css';

const Credits = () => (
    <div className="credits-content">
        <div className="credit-widget">
            <div className="title">Geliştirici</div>
            <div className="value">
                <a href="https://github.com/kerembeyazit" className="social-link">
                    <img width={16} src={githubIcon} alt=""/>
                    <span>Kerem Beyazıt</span>
                </a>
            </div>
        </div>
        <div className="credit-widget">
            <div className="title">Katkıda Bulunanlar</div>
            <div className="value">
                <a href="https://github.com/savasozcan" className="social-link">
                    <img width={16} src={githubIcon} alt=""/>
                    <span>Savaş Özcan</span>
                </a>
            </div>
        </div>
        <div className="credit-widget">
            <div className="title">Veri Kaynakları</div>
            <div className="value">
                <a href="https://open-meteo.com" className="source-link">OpenMeteo</a>
                <a href="https://positionstack.com" className="source-link">PositionStack</a>
            </div>
        </div>
        <hr/>
        <div className="credit-widget mt-15">
            <div className="title"><a className={"as-link"} target={"_blank"} href={"https://gist.github.com/kerembeyazit/884dfdae7ecc4b547f42cc10148a5b71"}>Açık Kaynak Yazılımlar</a></div>
        </div>
    </div>
);

export default Credits;
