import React from 'react';
import Popup from 'reactjs-popup';
import Credits from "./Credits";

const Footer = () => (
    <footer className="footer">
        <div className="footer-left">

        </div>
        <div className="footer-right">
            <div className="version">{process.env.REACT_APP_VERSION}</div>
            <Popup
                trigger={<a className="credits">Hakkında</a>}
                modal
                nested
            >
                {close => (
                    <div className="modal">
                        <div className="content">
                            <Credits/>
                        </div>
                    </div>
                )}
            </Popup>
        </div>
    </footer>
);

export default Footer;
