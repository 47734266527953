import React from 'react';
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'

const Details = (props) => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.extend(duration)

    const returnKm = () => {
        return (props.data.current.visibility / 1000).toFixed(2)
    }

    const returnSunrise = () => {
        let sunriseDate = props.data.daily.sunrise[0];

        return dayjs(sunriseDate).add(dayjs.duration({'h' : 3})).format("HH:MM");
    }

    return (
        <div className={"details"}>
            <div className="detail-widget">
                <div className="detail-title">Hissedilen Sıcaklık</div>
                <div className="detail-value">{props.data.current.apparent_temperature}<span>°C</span></div>
            </div>
            <div className="detail-widget">
                <div className="detail-title">Nem Oranı</div>
                <div className="detail-value">%{props.data.current.relative_humidity_2m}</div>
            </div>
            <div className="detail-widget">
                <div className="detail-title">Yağmur Olasılığı</div>
                <div className="detail-value">%{props.data.current.rain}</div>
            </div>
            <div className="detail-widget">
                <div className="detail-title">Kar Olasılığı</div>
                <div className="detail-value">%{props.data.current.snowfall}</div>
            </div>
            <div className="detail-widget">
                <div className="detail-title">Görünürlük</div>
                <div className="detail-value">{returnKm()} <span>km</span></div>
            </div>
            <div className="detail-widget">
                <div className="detail-title">Gün Doğumu</div>
                <div className="detail-value">{returnSunrise()}</div>
            </div>
        </div>
    )
};

export default Details;
