import React from 'react';
import locationPermissionNotificationImage from '../../images/notification-popup.svg';
import Weather from "../Weather/Weather"
import Footer from "../Footer";
import SunAnimation from "../Loading";

const PermissionStatus = ({status, weatherData, locationData, loading}) => {
    let content, title;

    switch (status) {
        case 'loading':
            title = '';
            content = <SunAnimation/>;
            break;
        case 'prompt':
        case 'denied':
            title = 'İzniniz gerekli';
            content = (
                <>
                    <img className="mt-15 mb-15 w-100" src={locationPermissionNotificationImage} alt=""/>
                    <p>Konumunuzdaki hava durumu bilgilerine erişmek için sol üst taraftaki konum iznine izin
                        veriniz.</p>
                </>
            );
            break;
        case 'error':
            title = ':(';
            content = 'Tarayıcınız konum bilgisini desteklemiyor.';
            break;
        case 'granted':
            if (weatherData) {
                const {temperature_2m} = weatherData.current;

                const getTempClass = (temp) => {
                    if (temp < 18){
                        return "winter"
                    } else if (temp >= 18 && temp <= 23){
                        return "spring";
                    } else {
                        return "summer";
                    }
                };
                const tempClass = getTempClass(temperature_2m);

                return (
                    <div className={`App`}>
                        <div className="main-content">
                            <Weather data={weatherData} weatherClass={tempClass} locationData={locationData}/>
                        </div>
                        <Footer/>
                    </div>
                );
            }
            return null;
        default:
            return null;
    }

    return (
        <div className="App">
            <div className="main-content text-left">
                {title && <div className="title mb-5">{title}</div>}
                <div className="content">
                    {content}
                </div>
            </div>
        </div>
    );
};

export default PermissionStatus;
