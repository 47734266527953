import React from 'react';

const BasicDetails = (props) => {
    const returnTemp = (temp) => {
        let stringTemp = temp.toString().split('.'),
            tempBeforeDot = stringTemp[0],
            tempAfterDot = stringTemp[1];

        if (tempBeforeDot === tempAfterDot) {
            return (
                <div className={"temp"}>
                    <span className="current-temp">{tempBeforeDot}</span>
                    <span className="after-dot">.{tempAfterDot}</span>
                    <span className="celcius">°C</span>
                </div>
            )
        } else {
            return (
                <div className={"temp"}>
                    <span className="current-temp">{tempBeforeDot}</span>
                    <span className="celcius no-after">°C</span>
                </div>
            )
        }


    };

    return (
        <React.Fragment>
            <div className="location">
                {props.locationData.state ? props.locationData.state + "," + props.locationData.country : "-"}
            </div>
            <div className="weather-data">
                {returnTemp(props.data.current.temperature_2m)}
            </div>
        </React.Fragment>
    )
};

export default BasicDetails;