import React, {useState} from 'react';
import BasicDetails from "./BasicDetails";
import Details from "./Details";

const Weather = (props) => {
    const [manager, setManager] = useState({
        details: false
    });

    const toggleDetails = (temp) => {
        let currentState = manager.details;

        setManager(prevState => ({
            ...prevState,
            details: currentState !== true,
        }));
    };

    return (
        <div className={`weather ${props.weatherClass}`}>
            {manager.details ? <Details data={props.data} locationData={props.locationData}/> : <BasicDetails data={props.data} locationData={props.locationData}/>}
            <div className="details-button">
                <div className="icon">
                    <svg width="12" height="12" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.1531 8.375H0.5V6.625H11.1531L6.25313 1.725L7.5 0.5L14.5 7.5L7.5 14.5L6.25313 13.275L11.1531 8.375Z"
                            fill="#FEF7FF"/>
                    </svg>
                </div>
                <div onClick={()=> toggleDetails()} className="label">
                    {manager.details ? 'Basit Detaylar' : 'Detaylar'}
                </div>
            </div>
        </div>
    )
};

export default Weather;
